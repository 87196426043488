import React, { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { OptionBase } from "contracts/Common";
import {
  SendContactMessageActions as Actions,
  SendContactMessageState as State,
} from "store/ducks/messages";
import { useValidation } from "hooks";
import { Input, Select, MaskedInput, Textarea } from "components/Shared";
import * as S from "./styles";

const enroledOptions: OptionBase[] = [
  {
    label: "Sou sócio",
    value: 1,
  },
  {
    label: "Não sou sócio",
    value: 0,
  },
];

const ContactForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [enroled, setEnroled] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { handleFormErrors, handleApiErrors } = useValidation();

  const { loading, validationErrors } = useSelector<RootStateOrAny, State>(
    (state) => state.sendContactMessage
  );

  const onEnroledChange = useCallback((option: OptionBase | null): void => {
    setEnroled(() => option?.value === 1);
  }, []);

  const onSuccess = useCallback((): void => {
    formRef?.current?.reset();
  }, []);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          ...(enroled && {
            register: Yup.string().required("Informe sua matrícula"),
          }),
          enroled: Yup.mixed()
            .required("Selecione uma opção")
            .transform((value) => {
              return value === 1;
            }),
          name: Yup.string().required("Informe seu nome completo"),
          email: Yup.string()
            .email("Informe um e-mail válido")
            .required("Informe seu e-mail"),
          phone: Yup.string().required("Informe seu WhatsApp/celular"),
          content: Yup.string().required("Redija sua mensagem"),
        });

        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(Actions.request(validData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [dispatch, enroled, handleFormErrors, onSuccess]
  );

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => {
    return () => {
      dispatch(Actions.reset());
    };
  }, [dispatch]);

  return (
    <S.Container>
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.FormRow>
          <Select
            name="enroled"
            label="Sócio:"
            options={enroledOptions}
            onChange={onEnroledChange}
          />
        </S.FormRow>
        {enroled && (
          <S.FormRow>
            <Input name="register" label="Matrícula:" />
          </S.FormRow>
        )}
        <S.FormRow>
          <Input name="name" label="Nome completo:" />
        </S.FormRow>
        <S.FormRow>
          <Input name="email" type="mail" label="E-mail:" />
        </S.FormRow>
        <S.FormRow>
          <MaskedInput
            name="phone"
            label="WhatsApp/Celular"
            mask={"(99) 99999-9999"}
          />
        </S.FormRow>
        <S.FormRow>
          <Textarea name="content" label="Mensagem:" />
        </S.FormRow>
        <S.FormActions>
          <S.Button type="submit">
            {loading ? <S.ActivityIndicator /> : "Enviar"}
          </S.Button>
        </S.FormActions>
      </Form>
    </S.Container>
  );
};

export default ContactForm;
